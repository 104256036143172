
import { Options, Vue } from "vue-class-component";
import { authAPI, userAPI } from "@/utils/apiCalls";
import router from "@/router";
import * as types from "@/utils/Types";

@Options({
  mounted() {
    this.getAuthTokens();
  },
  methods: {
    getAuthTokens() {
      //Extract the login token from the url, get the auth tokens and go home
      let token: string = new URLSearchParams(window.location.search).get(
        "code"
      ) as string;
      console.log("REDIRECT token", token);
      authAPI.getAuthTokens(token).then(() => {
        userAPI.getCurrentUserInfo().then((userInfo: types.userInfo | null) => {
          console.log("REDIRECT userinfo", userInfo);

          this.$store.commit("setUserInfo", userInfo);
          router.push("/");
        });
      });
    },
  },
})
export default class LoginRedirect extends Vue {}
